import { Typography } from "@mui/material";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import { GetPolicyTuningHpaCurrentDataResponse } from "../../../api/fetcher";
import Tooltip from "../../../components/Tooltip";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";
import { LABEL_BOX_CLASS_WRAPPER, METRIC_BOX_CLASS_NAME } from "./utils";

const ICON_SIZE = 12;
const MAX_TOOLTIP_WIDTH = 500;

const strategyType = {
  REPLICAS: "replicas",
  USAGE: "usage",
};

const BOX_CLASS_WRAPPER = `${METRIC_BOX_CLASS_NAME} ${LABEL_BOX_CLASS_WRAPPER}`;

const PercentileBox = ({
  percentile,
  optimizationStrategy,
}: {
  percentile?: number;
  optimizationStrategy?: string;
}) => {
  let tooltipContent = "Calculating prediction method";
  let strategyText = "";
  switch (true) {
    case optimizationStrategy === strategyType.REPLICAS && percentile === 100:
      tooltipContent = "Workload optimized based on the maximum replica count observed during the time window.";
      strategyText = "Max over window";
      break;
    case optimizationStrategy === strategyType.REPLICAS && percentile !== 100:
      tooltipContent = `Workload optimized based on the percentile: %${
        percentile || 0
      } replica count observed during the time window.`;
      strategyText = "Percentile over window";
      break;
    case optimizationStrategy === strategyType.USAGE:
      tooltipContent = "Workload shows predictable usage trends based on historical data.";
      strategyText = "Predictive";
      break;
  }

  const strategyLable = (
    <p>
      <b>Prediction method</b> {optimizationStrategy ? `: ${strategyText}` : ""}
    </p>
  );

  return (
    <Tooltip
      title={<Typography variant="caption">{tooltipContent} </Typography>}
      placement="top-end"
      maxWidth={MAX_TOOLTIP_WIDTH}
      disabled={!tooltipContent}
    >
      <div className={`${BOX_CLASS_WRAPPER} ${optimizationStrategy ? "" : " text-text-disable"}`}>
        {strategyLable}
        <div className="w-[12px]">
          <CreativeInfoIcon width={ICON_SIZE} height={ICON_SIZE} />
        </div>
      </div>
    </Tooltip>
  );
};

const OptimizationGapBox = ({ reason }: { reason: string }) => (
  <Tooltip
    title={
      <Typography variant="caption">
        <b>Optimization gap</b>
        <p className="italic">HPA optimization could not be achieved</p>
        <p>
          Workload has optimization gap due to: <span className="italic">{camelCaseToSnakeSpace(reason)}</span>.
        </p>
      </Typography>
    }
    placement="left"
    maxWidth={MAX_TOOLTIP_WIDTH}
  >
    <div className={BOX_CLASS_WRAPPER}>
      Optimization gap
      <div className="w-[12px]">
        <CreativeInfoIcon width={ICON_SIZE} height={ICON_SIZE} />
      </div>
    </div>
  </Tooltip>
);

interface Props {
  data: GetPolicyTuningHpaCurrentDataResponse | undefined;
}

const PredictableBadges = ({ data }: Props) => {
  if (!data) return null;
  return (
    <>
      {<PercentileBox percentile={data.percentile} optimizationStrategy={data?.optimizationStrategy} />}
      {data?.optimizationGap && <OptimizationGapBox reason={data?.optimizationGap ?? "tempMessage"} />}
    </>
  );
};

export default PredictableBadges;
