import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  GetNetworkAggregations,
  GetNetworkAggregationsResponse,
  GetNetworkCostEnabled,
  GetNetworkCostEnabledResponse,
  GetNetworkReportWorkloads,
  GetNetworkReportWorkloadsResponse,
} from "../../api/fetcher";
import { components } from "../../api/schema";
import PartialBorders from "../../components/PartialBorders";
import { useMainContext } from "../../MainContext";
import RunningNumberBox from "../Overview/TopOverviewSection/RunningNumberBox";
import useAggregationFilters from "./Tables/Aggregation/hooks/useAggregationFilters";
import { TableType } from "./Tables/utils";
import useWorkloadsFilters from "./Tables/Workloads/hooks/useWorkloadsFilters";

const INITIAL_METRICS = {
  totalCost: 0,
  intraAZCost: 0,
  crossAZCost: 0,
};

type METRICS = {
  totalCost: number;
  intraAZCost: number;
  crossAZCost: number;
};

const WRAPPER_CLASS_NAME = "w-full h-full";

const getMetrics = (workloads: components["schemas"]["NetworkCostWorkloadNetworkReport"][]) => {
  if (!workloads || workloads.length === 0) return INITIAL_METRICS;
  const initialState = { ...INITIAL_METRICS };

  return workloads.reduce((acc, curr) => {
    acc.totalCost += !Number.isNaN(curr.totalCost.total) ? curr.totalCost.total : 0;
    acc.intraAZCost += !Number.isNaN(curr.intraAZCost.total) ? curr.intraAZCost.total : 0;
    acc.crossAZCost += !Number.isNaN(curr.crossAZCost.total) ? curr.crossAZCost.total : 0;
    return acc;
  }, initialState);
};

const getNetworkReportWorkloads = GetNetworkReportWorkloads();
const getNetworkAggregations = GetNetworkAggregations();

const { queryFn: getNetworkCostEnabledQueryFn, queryKey: getNetworkCostEnabledQueryKey } = GetNetworkCostEnabled();

interface Props {
  selectedTable: TableType;
}

const TopNetworkMetrics = ({ selectedTable }: Props) => {
  const [metrics, setMetrics] = useState<METRICS>(INITIAL_METRICS);
  const { currentCluster } = useMainContext();

  const workloadsFilters = useWorkloadsFilters();
  const workloadsFiltersLength = Object.keys(workloadsFilters).length;
  const aggregationFilters = useAggregationFilters();
  const aggregationFiltersLength = Object.keys(aggregationFilters).length;

  const { data: workloadsData, isError: isWorkloadsError } = useQuery<GetNetworkReportWorkloadsResponse, Error>({
    queryKey: [getNetworkReportWorkloads.queryKey, workloadsFilters],
    queryFn: () =>
      getNetworkReportWorkloads.queryFn({
        ...workloadsFilters,
        multiCluster: true,
      }),
    enabled: selectedTable === TableType.Workloads && !!workloadsFiltersLength,
  });

  const { data: aggregationData, isError: isAggregationError } = useQuery<GetNetworkAggregationsResponse, Error>({
    queryKey: [getNetworkAggregations.queryKey, aggregationFilters],
    queryFn: () =>
      getNetworkAggregations.queryFn({
        ...aggregationFilters,
        multiCluster: true,
      }),
    enabled: selectedTable === TableType.Aggregation && !!aggregationFiltersLength,
  });

  const { data: getNetworkCostEnabledData } = useQuery<GetNetworkCostEnabledResponse, Error>({
    queryFn: () =>
      getNetworkCostEnabledQueryFn({
        multiCluster: true,
      }),
    queryKey: [getNetworkCostEnabledQueryKey],
    enabled: !!currentCluster,
  });

  useEffect(() => {
    let reducedData;
    switch (true) {
      case selectedTable === TableType.Workloads && !!workloadsData:
        reducedData = getMetrics(workloadsData?.aggregatedWorkloads || []);
        setMetrics(reducedData);
        break;
      case selectedTable === TableType.Aggregation && !!aggregationData:
        reducedData = getMetrics(aggregationData?.aggregatedWorkloads || []);
        setMetrics(reducedData);
        break;
    }
  }, [workloadsData, aggregationData, selectedTable]);

  useEffect(() => {
    setMetrics(INITIAL_METRICS);
  }, [isWorkloadsError, isAggregationError]);

  const isNetworkCostEnabledForCurrentCluster =
    !!currentCluster && !!getNetworkCostEnabledData?.networkCostEnabled?.[currentCluster];
  const isLoading = !workloadsData && !aggregationData;
  const showDashForNoData = isWorkloadsError || isAggregationError || !isNetworkCostEnabledForCurrentCluster;

  return (
    <div className="border bg-white border-border rounded-lg p-5 flex items-center justify-center relative h-[9.375rem]">
      <div className={WRAPPER_CLASS_NAME}>
        <PartialBorders>
          <RunningNumberBox
            title="Total network cost"
            value={metrics.totalCost}
            prefix="$"
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            isLoading={isLoading}
            showDashForNoData={showDashForNoData}
          />
        </PartialBorders>
      </div>
      <div className={WRAPPER_CLASS_NAME}>
        <PartialBorders left>
          <RunningNumberBox
            title="Cross-AZ cost"
            value={metrics.crossAZCost}
            prefix="$"
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            isLoading={isLoading}
            showDashForNoData={showDashForNoData}
          />
        </PartialBorders>
      </div>
      {/* <div className={WRAPPER_CLASS_NAME}>
        <PartialBorders left>
          <RunningNumberBox
            title="Intra-AZ cost"
            value={metrics.intraAZCost}
            prefix="$"
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            isLoading={isLoading}
            showDashForNoData={showDashForNoData}
          />
        </PartialBorders>
      </div> */}
    </div>
  );
};

export default TopNetworkMetrics;
