import { MenuItem } from "@mui/material";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import SingleSelect, { SingleSelectSize } from "../../../components/SingleSelect";

const OPTIONS = {
  "5 lines per graph": 5,
  "10 lines per graph": 10,
  "20 lines per graph": 20,
};

export const NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME = "numberOfElements";
export const DEFAULT_OPTION = OPTIONS["5 lines per graph"];

const NumberOfElementsFilter = () => {
  const [numberOfElements, setNumberOfElements] = useQueryParam(
    NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME,
    withDefault(NumberParam, DEFAULT_OPTION)
  );

  return (
    <div>
      <SingleSelect<number>
        size={SingleSelectSize.Regular}
        className="w-[180px]"
        selected={numberOfElements}
        setSelected={(option) => {
          setNumberOfElements(option);
        }}
        renderOptionsFunc={() =>
          Object.entries(OPTIONS).map((option) => {
            return (
              <MenuItem value={option[1]} key={option[1]}>
                <p className="text-[14px]">{option[0]}</p>
              </MenuItem>
            );
          })
        }
      />
    </div>
  );
};

export default NumberOfElementsFilter;
