import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import { ReactNode, useState } from "react";
import { GetPodsResponse } from "../../../api/fetcher";
import CircleCheckIcon from "../../../Icons/CircleCheckIcon";
import CloseIcon from "../../../Icons/CloseIcon";
import { CpuFormatter, MemoryFormatter } from "../../../utils/formatterUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../utils/useStateWithLocalStorage";
import WorkloadTooltip from "../../../utils/workloadTooltip";
import CustomColumnsFilterButton from "../../CustomColumnsFilterButton";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../../exportCSV/ExportCSV";
import MultiSelect from "../../MultiSelect";
import CustomTooltip from "../../Tooltip";

const DEFAULT_PAGE_SIZE = 5;
const ROWS_PER_PAGE_OPTIONS = Array.from({ length: 4 }, (_, i) => DEFAULT_PAGE_SIZE * (i + 1));
const NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY = "nodeDiagnosticsPageSize";
const memoryFormatter = MemoryFormatter();
const cpuFormatter = CpuFormatter();

interface Props {
  rows?: GetPodsResponse[];
  isLoading: boolean;
}

type CSVExportType = GetPodsResponse;

export type PodsRowEntry = {
  auto: boolean;
  binPacked: boolean;
  blockingMessage: string;
  blockingReason: string;
  cpuLimit: number;
  cpuRequest: number;
  memoryLimit: number;
  memoryRequest: number;
  cpuUsage: number;
  memoryUsage: number;
  name: string;
  namespace: string;
  ownerName: string;
  ownerType: string;
  phase: string;
  unevictable: boolean;
};

enum Columns {
  Pod = "Pod",
  CpuRequest = "CPU Request",
  MemoryRequest = "Memory Request",
  CpuLimit = "CPU Limit",
  MemoryLimit = "Memory Limit",
  CpuUsage = "CPU Usage",
  MemoryUsage = "Memory Usage",
  Unevictable = "Unevictable",
  //Blocked = "Blocked",
  //Affinities = "Affinities",
}

const COLUMNS_MENU_OPTIONS = [
  Columns.Pod,
  Columns.CpuRequest,
  Columns.MemoryRequest,
  Columns.CpuLimit,
  Columns.MemoryLimit,
  Columns.CpuUsage,
  Columns.MemoryUsage,
  Columns.Unevictable,
  //Columns.Blocked,
  //Columns.Affinities,
];

const renderNameCell = (value: string, tooltipContent: ReactNode) => {
  return (
    <div className="w-full">
      <CustomTooltip maxWidth={770} title={tooltipContent}>
        <Typography variant="body2" noWrap={true}>
          {value}
        </Typography>
      </CustomTooltip>
    </div>
  );
};

const renderMemoryCell = (value: string, showZero = true) => {
  if (!value) {
    return showZero ? 0 : "";
  }
  const memoryValue = memoryFormatter.format(Number(value));
  return (
    <Typography variant="body2" noWrap={true}>
      {memoryValue}
    </Typography>
  );
};

const renderCpuCell = (value: string, showZero = true) => {
  if (!value) {
    return showZero ? 0 : "";
  }
  const cpuValue = cpuFormatter.format(Number(value) / 1000);
  return (
    <Typography variant="body2" noWrap={true}>
      {cpuValue}
    </Typography>
  );
};

/*
Will be used soon for blocked and affinities columns
const renderTooltipBooleanCell = (value: string) => {
  return (
    <CustomTooltip title={value} disabled={!value}>
      <Typography variant="body2" noWrap={true}>
        {value && <WarningIcon width={14} height={14} fill={MAIN_YELLOW} />}
      </Typography>
    </CustomTooltip>
  );
};
*/

const DEFAULT_COL_PROPS: Partial<GridColDef> = {
  flex: 1,
  minWidth: 30,
  type: "string",
  align: "center",
  headerAlign: "center",
  disableColumnMenu: true,
  sortable: true,
};

const getColumns = (selectedColumns: Columns[]): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: Columns.Pod,
      headerAlign: "center",
      hide: !selectedColumns.includes(Columns.Pod),
      flex: 1.5,
      minWidth: 250,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, GetPodsResponse, string>) => {
        const namespace = params.row.namespace ?? "";
        const podName = params.value ?? "";
        return renderNameCell(
          `${namespace}/${podName}`,
          <WorkloadTooltip namespace={namespace} workloadName={podName} />
        );
      },
    },
    {
      field: "cpuRequest",
      headerName: Columns.CpuRequest,
      hide: !selectedColumns.includes(Columns.CpuRequest),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string),
    },
    {
      field: "cpuUsage",
      headerName: Columns.CpuUsage,
      hide: !selectedColumns.includes(Columns.CpuUsage),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string),
    },
    {
      field: "cpuLimit",
      headerName: Columns.CpuLimit,
      hide: !selectedColumns.includes(Columns.CpuLimit),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderCpuCell(params.value as string, false),
    },
    {
      field: "memoryRequest",
      headerName: Columns.MemoryRequest,
      hide: !selectedColumns.includes(Columns.MemoryRequest),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string),
    },
    {
      field: "memoryUsage",
      headerName: Columns.MemoryUsage,
      hide: !selectedColumns.includes(Columns.MemoryUsage),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string),
    },
    {
      field: "memoryLimit",
      headerName: Columns.MemoryLimit,
      hide: !selectedColumns.includes(Columns.MemoryLimit),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderMemoryCell(params.value as string, false),
    },
    {
      field: "unevictable",
      headerName: Columns.Unevictable,
      hide: !selectedColumns.includes(Columns.Unevictable),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) =>
        !params.value ? <CloseIcon width={20} height={20} /> : <CircleCheckIcon className="text-main-green" />,
    },
    /* Will be added soon, I hope :)
    {
      field: "blockingMessage",
      headerName: Columns.Blocked,
      hide: !selectedColumns.includes(Columns.Blocked),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderTooltipBooleanCell(params.value as string),
    },
    {
      field: "affinity",
      headerName: "Affinity",
      hide: !selectedColumns.includes(Columns.Affinities),
      ...DEFAULT_COL_PROPS,
      renderCell: (params) => renderTooltipBooleanCell(params.value as string),
    },
    */
  ];
};

const PodsTable = ({ rows, isLoading }: Props) => {
  const [selectedColumns, setSelectedColumns] = useState<Columns[]>([
    Columns.Pod,
    Columns.CpuRequest,
    Columns.MemoryRequest,
    Columns.CpuUsage,
    Columns.MemoryUsage,
  ]);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: DEFAULT_PAGE_SIZE,
    valueFormatter: (value) => parseInt(value),
  });
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>([
    {
      field: "name",
      sort: "asc",
    },
  ]);

  if (isLoading && !rows) {
    return (
      <div className="w-full border pt-2 pb-6 relative flex items-center justify-center h-[350px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full justify-end gap-4">
        <MultiSelect
          selected={selectedColumns}
          setSelected={setSelectedColumns as React.Dispatch<React.SetStateAction<(string | undefined)[]>>}
          options={COLUMNS_MENU_OPTIONS}
          className="mb-[6px] w-[85px]"
          customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
        />
      </div>
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        rows={rows ?? []}
        columns={getColumns(selectedColumns)}
        autoHeight={true}
        rowHeight={65}
        getRowId={(row: GetPodsResponse) => row.name}
        loading={isLoading}
        disableSelectionOnClick
        pagination
        onSortModelChange={(newSortModel: GridSortModel) => {
          setSortModel(newSortModel);
        }}
        sortModel={sortModel}
        initialState={{
          pagination: {
            pageSize: DEFAULT_PAGE_SIZE,
          },
          sorting: {
            sortModel: [
              {
                field: "name",
                sort: "asc",
              },
            ],
          },
        }}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          localStorage.setItem(NODE_DIAGNOSTICS_PAGE_SIZE_LOCAL_STORAGE_KEY, newPageSize.toString());
        }}
      />
      {HAS_EXPORT_TABLE_AS_CSV && rows && (
        <div className="mt-[-35px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="node_pods.csv"
            columns={[
              "name",
              "cpuRequest",
              "cpuUsage",
              "cpuLimit",
              "memoryRequest",
              "memoryUsage",
              "memoryLimit",
              "unevictable",
            ]}
            columnsToRound={["cpuRequest"]}
            data={
              rows?.map((row) => ({
                name: row.name,
                cpuRequest: row.cpuRequest,
                cpuUsage: row.cpuUsage,
                cpuLimit: row.cpuLimit,
                memoryRequest: row.memoryRequest,
                memoryUsage: row.memoryUsage,
                memoryLimit: row.memoryLimit,
                unevictable: row.unevictable,
              })) as CSVExportType[]
            }
            columnsToSum={["cpuRequest", "memoryRequest", "cpuLimit", "memoryLimit"]}
            customColumnNames={{
              name: Columns.Pod,
              cpuRequest: Columns.CpuRequest,
              cpuUsage: Columns.CpuUsage,
              cpuLimit: Columns.CpuLimit,
              memoryRequest: Columns.MemoryRequest,
              memoryUsage: Columns.MemoryUsage,
              memoryLimit: Columns.MemoryLimit,
              unevictable: Columns.Unevictable,
            }}
          />
        </div>
      )}
    </>
  );
};

export default PodsTable;
